$primary-nav-width: 12%;

#primary-nav .level-1 {
    width: $primary-nav-width;

    @include breakpoint($sm) {
        padding: 8px 5px;

        /*&.is-open:after {
            border-bottom: none;
        }*/
    }

    @include breakpoint($md){
        width: 12%;

        &:nth-child(2) {
            width: 15%;
        }
        &:nth-child(6) {
            width: 13%;
        }

        &:after {
            display: none;
        }
        /*&.is-open:after {
            display: none;
            // border-bottom: none;
        }*/
    }

    @include breakpoint($lg) {

        &:nth-child(2) {
            width: 15%;
        }
        &:nth-child(6) {
            width: 13%;
        }

        /*&.is-open:after {
            border-bottom: 10px solid $wcm-dark-orange;
        }*/
        &:after {
            display: inherit;
        }
    }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
    max-width: none;
    height: 50px;
    border: 1px solid $wcm-border-gray;
    box-shadow: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 15px top 50%;
    background-size: 20px 20px;
    padding-right: 50px;
}

iframe {
    width: 100%;
}

.title-bar {
    font-family: $wcm-regular;
    padding-top: 5px;
    @include breakpoint($xs) {
        font-size: 18px;
        // line-height: 2;
    }
}

.brand__lockup img {
    height: 84px;
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

/*.contact-info-pane {
    font-size: 14px;
}*/
.information-sidebar {
    font-size: 14px;
}

.view-related-links {
    ul {
        margin-bottom: 0;
    }
}

.view-profiles {
    table {
        thead {
            th {
                background: $wcm-dark-gray;
                color: $wcm-white;
                text-align: center;
            }
        }
        td:first-child {
            width: 55%;
        }
        td:last-child {
            width: 45%;
        }
    }

    .staff_profile_image {
        float: left;
        margin-right: 15px;
        width: 22%;
        @include breakpoint($xs) {
            width: 100px;
            float: none;
        }

        img { 
            border: 1px solid $wcm-med-gray;
        }
    }
    .staff_info {
        @include breakpoint($xs) {
            width: auto;
            float: none;
        }
        width: 73%;
        float: left;

        .staff_office_address {
            clear: both;
        }

        /*.field-collection-item-field-location {
            .content {
                div { display: inline-block; }
                div:last-child {
                    &:before {
                        content: ', ';
                    }
                }
            }
        }*/
    }

    .views-field-field-biographical-info {
        ul {
            padding-left: 20px;
        }
    }
}

#play-sds-video {
    display: block;
    text-align: center;

    img {
        max-width: 350px;
    }
}

.sds-demo {
    display: none;
    height: 300px;

    @include breakpoint($sm) {
        height: 500px;
    }

    &.sds-demo-active {
        display: block;
    }
}

.page-node-446 {
    .webform-component-matrix {
        .select2-container
        {
            width: 105px !important;
        }
    }
}

.pane-announcements-panel-pane-2 {
    padding: 20px;
    margin-top: 30px;
    background: $wcm-bg-gray;

    .view-header {
        h2 {
            margin-top: 0;
            padding-bottom: 10px;
            border-bottom: 1px solid $wcm-border-gray;
        }
    }
    .bx-controls {
        border-top: 1px solid $wcm-border-gray;
    }

    .bx-wrapper .bx-controls-direction a {
        top: inherit;
        margin-top: 10px;
        text-decoration: none;

        &.bx-prev {
            background: none;
            &:after {
                text-indent: 0;
                font-size: 24px;
                position: absolute;
                color: $wcm-bright-orange;
                left: 5px;
                top: 3px;
                text-decoration: none;
                content: '\e810';
                @include fontello();
            }
        }
        &.bx-next {
            background: none;
            &:after {
                text-indent: 0;
                font-size: 24px;
                position: absolute;
                color: $wcm-bright-orange;
                left: 8px;
                top: 3px;
                text-decoration: none;
                content: '\e802';
                @include fontello();
            }
        }
    }

    .announcement-title a {
        color: $wcm-dark-gray;
    }
}

.view-announcements {
    .announcement-date {
        padding: 5px 0;
        font-size: 13px;
        font-style: italic;
        font-weight: 600;
    }
}

.front {

    .select2-container{
        width: 100% !important;
    }

    .btn--wcm {
        margin-left: 0;
    }

    .radix-layouts-header {
        margin-top: 25px;
    }
    .pane-title {
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .fieldable-panels-pane {

        h2 { 
            margin-bottom: 20px;
        }
        ul {
            padding-left: 0px;
            list-style-position: inside;

            li {
                border-bottom: 1px solid $wcm-border-gray;
                padding: 5px 0;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}


#edit-chemical_info-14 {
    input[type="submit"] {
        margin-right: 10px;
    }
}

.webform-component--large-size, .webform-component--regular-size, .webform-component--small-size {
    border-top: 1px solid $wcm-border-gray;
    clear: both;
    padding-top: 10px;
    margin-bottom: 10px;
}
.webform-component-markup {
    @include clearfix();
}

.radio-list-underline {
    .form-type-radio {
        border-bottom: 1px solid $wcm-border-gray;
        padding: 5px 0;
        margin: 0;
    }
}

.page-node-43 {
    div.caption-left {
        @include breakpoint($md) {
            margin-top: 0;
        }
    }
    hr {
        width: 100%;
    }
}

.ctools-collapsible-container {
    .ctools-toggle {
        display: none;
    }
}
.ctools-collapsible-processed {
    .ctools-collapsible-handle h2 {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: $wcm-dark-orange;
        border: 1px solid $wcm-border-gray;
        border-width: 3px 0 0;
        cursor: pointer;
        margin: 0;
        padding: 15px 55px 15px 10px;
        font-weight: 600;

        &:hover {
            color: $wcm-bright-orange;
        }

        &:after {
            content: '-';
            width: 50px;
            display: block;
            position: absolute;
            top: 15px;
            right: 0;
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            border-left: 3px solid $wcm-border-gray;
        }
    }
    
    &.ctools-collapsed .ctools-collapsible-handle h2:after {
        content: '+';
    }
}